import { axiosCall } from "./_utils"
import {
  IMutationCreatePaymentAccountInput,
  IMutationDeletePaymentAccountInput,
  IMutationCreateOneTimeDepositInput
} from "@interfaces"

export const createPaymentAccount = async (
  variables: IMutationCreatePaymentAccountInput
) => {
  const mutation = `
    mutation($createPaymentAccountInput: CreatePaymentAccountInput!){
      createPaymentAccount(input: $createPaymentAccountInput){
        paymentAccount {
          id
        }
      }
    }
  `

  return axiosCall(mutation, variables)
}

export const deletePaymentAccount = async (
  variables: IMutationDeletePaymentAccountInput
) => {
  const mutation = `mutation($deletePaymentAccountInput: DeletePaymentAccountInput!) {
    deletePaymentAccount (input: $deletePaymentAccountInput) {
      paymentAccount {
        id
      }
    }
  }`

  return axiosCall(mutation, variables)
}

export const createOneTimeDeposit = async (
  variables: IMutationCreateOneTimeDepositInput
) => {
  const mutation = `mutation($createUserOneTimeDepositInput: CreateUserOneTimeDepositInput!) {
    createUserOneTimeDeposit(input: $createUserOneTimeDepositInput) {
      userOneTimeDeposit {
        id
        amount
      }
    }
  }`
  return axiosCall(mutation, variables)
}
