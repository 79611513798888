import React from "react"
import * as headers from "@data/headers"
import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { makeRandomString } from "@services/utils"

export const axiosCall = (query: string, variables = {}, extra = {}) => {
  const _id = `GQL-${makeRandomString()} : `

  return new Promise((resolve, reject) => {
    const request = {
      query,
      variables: JSON.stringify(variables),
    }

    const options: AxiosRequestConfig = {
      method: "POST",
      headers: headers.getHeaders(),
      url: headers.api,
      data: JSON.stringify(request),
    }

    axios(options)
      .then((res: AxiosResponse) => {
        if (res.data.errors) {
          reject(res.data.errors[0].message)
        } else {
          resolve(res)
        }
      })
      .catch((error: any) => {
        reject(error)
      })
  })
}
