import React from "react"
import SEO from "@components/seo"
import * as data from "@data/parentDash"
import Layout from "@components/layout"
import BankManagement from "@components/BankManagement"

const BankSettingsPage = () => {
  const { linkedAccount } = data
  return (
    <Layout type="app">
      <SEO title={linkedAccount.title} />
      <BankManagement />
    </Layout>
  )
}

export default BankSettingsPage
