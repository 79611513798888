import React, { useState } from "react"
import "./style.scss"
import "@components/_atoms/_style/_forms.scss"
import BackPage from "@components/_molecules/back_parent_dashboard"
import * as errors from "@services/errors"
import Loading from "@components/_molecules/loading_state"
import UnlinkBank from "@components/_atoms/unlink_bank"
import ConfirmModal from "@components/_molecules/modal_confirm"
import { deletePaymentAccount } from "@services/graphql"
import { IMutationDeletePaymentAccountInput } from "@interfaces"
import HiddenErrorHandler from "@components/_atoms/HiddenErrorHandler"

interface IBankManagementState {
  accountNumber: number | string | null
  bankName: string | null
  generalError: string | null
  paymentAccountId: string
  loading: boolean
  receivedRedirectUri: string | null
  ask: boolean
}

function BankManagement() {
  const [state, setState] = useState<IBankManagementState>({
    accountNumber: null,
    bankName: null,
    generalError: null,
    paymentAccountId: "",
    loading: true,
    receivedRedirectUri: null,
    ask: false,
  })

  const handleUnlinkSubmit = () => {
    setState({ ...state, ask: true })
  }
  const handleCancel = () => {
    setState({ ...state, ask: false })
  }
  const handleConfirm = () => {
    setState({ ...state, ask: false, loading: true })

    const variables: IMutationDeletePaymentAccountInput = {
      deletePaymentAccountInput: {
        id: state.paymentAccountId,
      },
    }

    deletePaymentAccount(variables)
      .then((res) => {
        location.reload()
      })
      .catch((error) => {
        errorHandler(error)
        setState({ ...state, loading: false })
      })
  }

  const errorHandler = (err: any) => {
    let general_error = errors.messaging(err) || null
    setState({ ...state, generalError: general_error })
  }

  return (
    <section className="standard">
      <div className="heading-size">
        <BackPage data={{ pageTitle: "Linked Account", link: "/app/" }} />
        {state.ask && (
          <ConfirmModal
            data={{
              title: "Unlink Your Bank Account",
              copy: "Are you sure you would like to unlink your bank account?",
            }}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )}
      </div>
      <div className="bank-management">
        <p className="label">Bank Settings</p>
        <Loading className="account" loading={state.loading}>
          <p>
            {state.bankName !== null ? state.bankName : "Linked Bank Account"}
          </p>
          <p>
            {state.accountNumber !== null
              ? "*****" + state.accountNumber
              : "N/A"}
          </p>
        </Loading>

        <div>
          <HiddenErrorHandler error={state.generalError} />
          {state.bankName === null && (
            <div className="btn-spacing form-button">
              <button
                className="btn btn-primary form-button-large"
              >
                Link a Bank
              </button>
            </div>
          )}
          {state.bankName !== null && (
            <UnlinkBank runUnlink={handleUnlinkSubmit}>
              Unlink Bank Account
            </UnlinkBank>
          )}
        </div>
      </div>
    </section>
  )
}

export default BankManagement
