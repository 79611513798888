import React, { Component } from "react"
import "@components/_atoms/general_submit_message/style.scss"

interface IComponentProps {
  error: string | null
  text?: string
}

interface IComponentState {
  learnMore: boolean
}

class HiddenErrorHandler extends Component<IComponentProps, IComponentState> {
  constructor(props: IComponentProps) {
    super(props)
    this.state = {
      learnMore: false
    }
  }
  render() {
    const { error, text } = this.props

    return (
      <div className="general-submit-message">
        <div className={`standard-text ${error !== null ? `error` : ``}`}>
          {error !== null ? error : text ? text : ""}
        </div>
      </div>
    )
  }
}
export default HiddenErrorHandler
